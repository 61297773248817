import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"
import SignUpSection from "components/organisms/SignUpSection"
import CollectionsBannerSection from "components/organisms/CollectionsBannerSection"

import Layout from "components/molecules/Layout"

import Section from "components/atoms/Section"
import Container from "components/atoms/Container"
import GraphQLErrorList from "components/atoms/Error/graphql-error-list"
import SEO from "components/atoms/SEO"

import { StoreContext } from "context/store"
import { useFlavorAsProduct } from "consts/helper"
import { loadStamped } from "../utils/stamped"
import useBlackFriday from "hooks/useBlackFriday"
import RebuyProducts from "components/molecules/Rebuy/RebuyProduts"
import ProductCollectionsItem from "templates/ProductCollectionsItem/ProductCollectionsItem"

export const query = graphql`
  query CategoryTemplateQuery($id: String!) {
    category: sanityCategory(_id: { eq: $id }) {
      meta {
        metaTitle
        metaDescription
      }
      _id
      title
      slug {
        current
      }
      products {
        ...Product
      }
    }
    categoryBanners: allSanityPage(filter: { title: { eq: "Collections" } }) {
      nodes {
        id
        _rawContentBlocks(resolveReferences: { maxDepth: 10 })
      }
    }
    shopifyVariants: allShopifyProductVariant {
      nodes {
        sku
        storefrontId
        shopifyId
        inventoryQuantity
        price
        title
      }
    }
  }
`

const CategoryTemplate = props => {
  const { enableDiscount } = useBlackFriday()
  const { isReBuyPopupOpen, setReBuyPopupOpen } = useContext(StoreContext)

  let {
    data: {
      category: { meta, title, products },
      categoryBanners,
      shopifyVariants,
    },
    errors,
  } = props

  products = useFlavorAsProduct(products)
  useEffect(() => {
    products.forEach(product => {
      if (enableDiscount) {
        product.flavors.forEach(allowDiscountItem => {
          allowDiscountItem.allowDiscount = true
        })
      } else {
        product.flavors.forEach(allowDiscountItem => {
          allowDiscountItem.allowDiscount = false
        })
      }
    })

    loadStamped()
  }, [products])

  const banner = categoryBanners?.nodes[0]?._rawContentBlocks?.filter(bannerData => bannerData.title === title)[0]

  return (
    <Layout>
      {errors && (
        <>
          <h2>GraphQL Error</h2>
          <GraphQLErrorList errors={errors} />
        </>
      )}
      <SEO title={meta?.metaTitle} description={meta?.metaDescription} />
      <CollectionsBannerSection content={banner} />

      <Section className="collections-products-sections bg-section">
        <Container>
          {isReBuyPopupOpen && <RebuyProducts onClose={() => setReBuyPopupOpen(false)} />}
          <div className="product-collections category-collections">
            {products?.map((product, index) => (
              <ProductCollectionsItem product={product} key={index} index={index} shopifyVariants={shopifyVariants} />
            ))}
          </div>
        </Container>
      </Section>

      <SignUpSection />
    </Layout>
  )
}

export default CategoryTemplate
