import { currencyFormat } from "consts/helper"
import React from "react"
import DiscountCompare from "../DiscountCompare/DiscountCompare"

const ComparePrice = ({ price, comparePrice, quantity }) => {
  const isDisconted = comparePrice > price

  if (isDisconted) {
    return (
      <>
        <span className="mf-price">${currencyFormat(comparePrice * quantity)}</span>
        <span className="disc-price">${(price * quantity).toFixed(2)}</span>
        <DiscountCompare
          isSubscribe={false}
          price={price}
          comparePrice={comparePrice}
          showPrice={false}
          quantity={quantity}
        />
      </>
    )
  }

  return <span>${currencyFormat(price * quantity)}</span>
}

export default ComparePrice
