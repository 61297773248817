import React, { useState, useEffect } from "react"
import Section from "components/atoms/Section"
import Container from "components/atoms/Container"
import Image from "components/atoms/Image"
import moment from "moment-timezone"
import { blackFridayTimer } from "consts/helper"
import BannerCountdown from "./BannerCountdown"

const CollectionsBannerSection = ({ content }) => {
  const { includeWeeklyCountdown, bgHexCode, description, image, title } = content

  const [countDown, setCountDown] = useState({ hidden: false })

  useEffect(() => {
    const interval = setInterval(() => {
      const endOfWeek = moment().day(7).endOf("day")
      const countDown = blackFridayTimer(endOfWeek)
      if (countDown?.distance > 0) {
        setCountDown(countDown)
      } else {
        setCountDown({
          hidden: true,
        })
        return () => clearInterval(interval)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [includeWeeklyCountdown])

  if (!countDown.distance || countDown.hidden) return null

  return (
    <Section
      className={`collections-banner-sections inner-padding ${includeWeeklyCountdown ? "deals" : "normal"}`}
      bgColor={bgHexCode.hex}
    >
      <Container>
        <div className="collections-banner-inner">
          <div className="product-banner-cols left">
            <h1 className="banner-title">{title}</h1>
            <p className="banner-tagline">{description}</p>
            {includeWeeklyCountdown && (
              <div className="banner-tagline">
                <BannerCountdown countdown={countDown} />
              </div>
            )}
          </div>
          <div className="product-banner-cols right">
            <Image image={image.asset} className="banner-img" isData />
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default CollectionsBannerSection
