import React, { useState, useEffect } from "react"
import CustomSelect from "components/atoms/CustomSelect"

import { SelectOptionFlavors } from "consts/helper"

const ProductIngredientsModal = ({ product, selFlavor, onClose }) => {
  const flavors = product.flavors.filter(flavor => flavor.isFlavor)
  const { serving } = product._rawContentBlocks.filter(content => content._type === "productFactsBlock")[0]

  const [total, setTotal] = useState([])
  const [inSelFlavor, setInSelFlavor] = useState(selFlavor)

  const { calories, carbohydrates, fats, ingredients, protein, servingSize, nutritionSuffix, breakdown } =
    inSelFlavor?._rawNutritionFacts

  useEffect(() => {
    let data = []
    breakdown?.forEach(item => data.push(item.value))
    setTotal(data)
  }, [selFlavor])

  const handleFlavor = event => {
    const tempSelFlavor = flavors.filter(flavor => flavor.flavor === event.label && flavor.sku === event.value)
    tempSelFlavor.length && setInSelFlavor(tempSelFlavor[0])
  }

  return (
    <div className="product-ingredients-modal-wrapper active">
      <div className="backdrop"></div>
      <div className="product-ingredients-popup-wrapper">
        <div className="product-ingredients-popup">
          <div className="popup-content">
            <div className="popup-close-wrapper">
              <div className="close" onClick={onClose}>
                <i className="icon-close" aria-hidden="true"></i>
              </div>
            </div>
            <div className="facts-section-inner">
              <div className="facts-details">
                <div className="flavor-dropdown">
                  <CustomSelect
                    options={SelectOptionFlavors(flavors)}
                    placeholder={inSelFlavor.flavor}
                    onChange={handleFlavor}
                  />
                </div>

                <div className="facts-top-extra">
                  <p className="facts-desc">
                    <strong>Ingredients: </strong>
                    {ingredients}
                  </p>
                </div>
                <div className="facts-title">Nutrition Facts</div>
                <div className="facts-text">{`${serving} serving per container`}</div>
                <div className="fact-title-flex">
                  <div className="fact-title-flex-inner">
                    <div className="facts-subtitle">Serving size</div>
                    <div className="facts-subtitle">{servingSize}</div>
                  </div>
                </div>
                <div className="fact-title-flex calories">
                  <div className="fact-title-flex-inner">
                    <div className="fact-title-flex-inner-left">
                      <div className="facts-text">Amount per serving</div>
                      <div className="facts-subtitle">Calories</div>
                    </div>
                    <div className="facts-subtitle font-40">{calories}</div>
                  </div>
                </div>

                <div className="facts-calories-table-wrapper">
                  <div className="facts-calories-table">
                    <div className="facts-calories-row">Total Fat</div>
                    <div className="facts-calories-row">{fats.totalFat}g</div>
                  </div>
                  <div className="facts-calories-table sub-table">
                    <div className="facts-calories-row">Saturated Fat</div>
                    <div className="facts-calories-row">{fats.saturatedFat}g</div>
                  </div>
                  <div className="facts-calories-table sub-table">
                    <div className="facts-calories-row">Trans Fat</div>
                    <div className="facts-calories-row">{fats.transFat}g</div>
                  </div>
                  <div className="facts-calories-table">
                    <div className="facts-calories-row">Total Carbohydrate</div>
                    <div className="facts-calories-row">{carbohydrates.totalCarbohydrate}g</div>
                  </div>
                  <div className="facts-calories-table sub-table">
                    <div className="facts-calories-row">Dietary Fiber</div>
                    <div className="facts-calories-row">{carbohydrates.dietaryFiber}g</div>
                  </div>
                  <div className="facts-calories-table sub-table">
                    <div className="facts-calories-row">Total Sugar</div>
                    <div className="facts-calories-row">{carbohydrates.totalSugar}g</div>
                  </div>
                  <div className="facts-calories-table inner-table">
                    <div className="facts-calories-row">Includes {carbohydrates.addedSugar}g Added Sugars</div>
                  </div>
                  <div className="facts-calories-table sub-table">
                    <div className="facts-calories-row">Sugar Alcohol</div>
                    <div className="facts-calories-row">{carbohydrates.sugarAlcohol}g</div>
                  </div>
                  <div className="facts-calories-table">
                    <div className="facts-calories-row">Protein</div>
                    <div className="facts-calories-row">1{protein}g</div>
                  </div>
                </div>
              </div>
              <div className="facts-details text-center">
                <div className="facts-details-top">It’s simple Math.</div>
                <div className="facts-details-mid">
                  {breakdown?.map((flavor, index) => (
                    <div className="facts-details-inner" key={index}>
                      {index > 0 ? `-` : ``} {flavor?.value}g {flavor?.text}
                    </div>
                  ))}
                </div>
                <div className="facts-details-bottom">
                  = {total.length > 0 && total.reduce((v, i) => v - i)}g Net Carbs Per {nutritionSuffix}
                </div>
              </div>
              <div className="facts-details">
                <div className="facts-details-table">
                  <div className="facts-details-row">Carbs</div>
                  <div className="facts-details-row">{carbohydrates.totalCarbohydrate}g</div>
                  <div className="facts-details-row">
                    <div className="k-progress red">
                      <span className="k-pro" style={{ width: `${carbohydrates.totalCarbohydrate}%` }}></span>
                    </div>
                  </div>
                </div>
                <div className="facts-details-table">
                  <div className="facts-details-row">Protein</div>
                  <div className="facts-details-row">{protein}g</div>
                  <div className="facts-details-row">
                    <div className="k-progress yellow">
                      <span className="k-pro" style={{ width: `${protein}%` }}></span>
                    </div>
                  </div>
                </div>
                <div className="facts-details-table">
                  <div className="facts-details-row">Fat</div>
                  <div className="facts-details-row">{fats.totalFat}g</div>
                  <div className="facts-details-row">
                    <div className="k-progress green">
                      <span className="k-pro" style={{ width: `${fats.totalFat}%` }}></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductIngredientsModal
