import React from "react"
import "./style.scss"

const SubQtyMobileBadge = ({ qty, selFlavor }) => {
  const { subQtyBg, subQtyItems, subQtyPos, subQtyTextColor } = selFlavor
  const subQtyExist = subQtyItems.length > 0

  return subQtyExist ? (
    <div
      className={`hidden subQtyBadgeContainer plp-subQtyBadgeContainer ${subQtyPos ? "subQtyLeft" : "subQtyRight"}`}
      style={{ backgroundColor: subQtyBg?.hex, borderColor: subQtyTextColor?.hex }}
    >
      <div className="circleText subQtyCnt">{subQtyItems[qty]?.subQty}</div>
      <div className="circleText subQtyText">{subQtyItems[qty]?.subQtyUnit}</div>
    </div>
  ) : (
    " "
  )
}

export default SubQtyMobileBadge
