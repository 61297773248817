import React from "react"

const BannerCountdown = ({ countdown }) => {
  const { days, hours, minutes, seconds } = countdown
  return (
    <>
      {days > 1 ? `${days} days` : days === 1 ? `${days} day` : ""}{" "}
      {hours > 1 ? `${hours} hrs` : hours === 1 ? `${hours} hr` : ""}{" "}
      {minutes > 1 ? `${minutes} mins` : minutes === 1 ? `${minutes} min` : ""}
      {` ${seconds} seconds`} left
    </>
  )
}

export default BannerCountdown
